<template>
  <div class="container">
    <b-overlay :show="isLoading">
      <vue-pdf-embed
        :source="loadPdf()"
        @rendered="isLoading = false"
      />
    </b-overlay>
  </div>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'

import cguEn from '@/assets/pdf/DIVEMA-CGU_en.pdf'
import cguFr from '@/assets/pdf/DIVEMA-CGU_fr.pdf'

export default defineComponent({
  name: 'TermsOfUse',

  components: {
    VuePdfEmbed,
  },

  setup(_props, ctx) {
    const { $store } = ctx.root

    const cgu = {
      en: cguEn,
      fr: cguFr,
    }

    const isLoading = ref(true)

    const loadPdf = () => cgu[$store.state.appConfig.lang] || cgu.fr

    return {
      loadPdf,
      isLoading,
    }
  },
})
</script>
