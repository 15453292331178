import store from '@/store/store'

export default {
  methods: {
    twoDecimalCurrencyItn(number) {
      return new Intl.NumberFormat(this.$i18n.locale, {
        style: 'currency',
        currency: store.state.appConfig.currency,
        currencyDisplay: 'symbol',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2,
        useGrouping: false,
      }).format(number)
    },
  },
}
