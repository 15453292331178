<template>
  <b-sidebar
    id="sidebar-add-payment-method"
    no-header
    :visible="isAddNewPaymentMethodSidebarActive"
    @change="$emit('update:is-add-new-payment-method-sidebar-active', $event)"
  >
    <template #default="{ hide }">
      <!-- HEADER -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ $t('account.payment_method.title') }}
        </h5>
        <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="hide" />
      </div>
      <!-- BODY -->
      <b-form class="p-1">
        <b-overlay class="my-3" :show="paymentMethodLoading" spinner-medium>
          <div ref="paymentMethod" />
          <!-- FOOTER -->
          <div class="d-flex mt-2">
            <b-button v-ripple.400="'rgba(186, 191, 199, 0.15)'" variant="outline-secondary" class="mr-1" @click="hide">
              <font-awesome-icon icon="ban" class="mr-50" />
              {{ $t('action.cancel') }}
            </b-button>
            <b-button :disabled="lockSubmit" @click="onSubmit">
              <feather-icon icon="SendIcon" class="mr-50" />
              {{ $t('action.submit') }}
            </b-button>
          </div>
        </b-overlay>
      </b-form>
    </template>
  </b-sidebar>
</template>

<script>
import { mapActions } from 'vuex'

import stripe from '@/libs/stripes'

export default {
  props: {
    isAddNewPaymentMethodSidebarActive: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      paymentMethodLoading: true,
      lockSubmit: true,
      stripe: null,
      payment: null,
      intent: null,
      appearance: {
        theme: 'stripe',
      },
      elements: null,
      paymentElement: null,
      paymentEvent: null,
    }
  },
  updated() {
    if (this.isAddNewPaymentMethodSidebarActive) {
      this.APIFetchStripeIntent()
    }
  },
  methods: {
    ...mapActions('stripe', ['fetchStripeIntentToken']),
    APIFetchStripeIntent() {
      this.paymentMethodLoading = true
      stripe.then(response => {
        this.stripe = response
        const tokenNum = this.$route.query.token
        this.fetchStripeIntentToken(tokenNum)
          .then(intent => {
            // SET INTENT
            this.intent = intent.data
            this.createAndMountFormElements()
          })
          .finally(() => {
            this.paymentMethodLoading = false
          })
      })
    },
    createAndMountFormElements() {
      this.elements = this.stripe.elements(
        {
          clientSecret: this.intent.client_secret,
          appearance: this.appearance,
          locale: this.$store.state.appConfig.lang,
        },
      )
      // Create and mount the Payment Element
      this.paymentElement = this.elements.create('payment')
      this.paymentElement.mount(this.$refs.paymentMethod)
      this.paymentMethodLoading = false
      this.paymentElement.on('change', event => {
        this.paymentEvent = event
        if (event.complete) {
          this.lockSubmit = false
        }
      })
    },
    onSubmit() {
      this.lockSubmit = true
      const { elements } = this
      this.stripe
        .confirmSetup({
          elements,
          confirmParams: {
            // Return URL where the customer should be redirected after the SetupIntent is confirmed.
            // @todo: ajouter lien direct vers la tab payment-method
            return_url: `${window.location.origin}/registration`,
          },
          redirect: 'if_required',
        })
        .then(result => {
          if (result.error) {
            this.toaster(result.error.message, 'danger', 'AlertTriangleIcon')
            this.lockSubmit = false
          } else {
            this.$emit('update:is-add-new-payment-method-sidebar-active', false)
            this.$emit('payment-done')
            this.lockSubmit = false

            if (result.setupIntent) {
              this.intent = result.setupIntent
              this.$emit('setup-intent', this.intent)
            }
          }
        })
        .catch(() => {
          this.lockSubmit = false
        })
    },
  },
}
</script>
