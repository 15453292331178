<template>
  <div>
    <app-disconnected-header />

    <b-nav
      id="navbarTabRegistration"
      :horizontal="$store.getters['app/mdAndUp']"
      :tabs="$store.getters['app/smAndDown']"
      class="mb-2 mx-3"
    >
      <b-nav-item
        v-for="(tab, index) in tabs"
        :key="tab.name"
        :active="activeTabIndex === index"
        href="javascript:void(0)"
      >

        <div
          class="text-nowrap"
          @click="switchTab(tab.component)"
        >

          <b-button :variant="tab.component === activeTab ? 'primary' : ''" class="nav-btn p-0">
            <span>{{ index + 1 }}</span>
          </b-button>
        </div>

      </b-nav-item>
    </b-nav>

    <div class="line"></div>

    <b-card class="mb-0 mx-3 p-2" no-body>
      <keep-alive>
        <component
          :is="activeTab"
          ref="tabs"
          :selected-offer="selectedOffer"
          :selected-offer-id="Number(selectedOffer.id)"
          :setup-intent-id="setupIntent.id"
          @onFilesUploaded="val => (saveLoader = !val)"
          @payment-done="paymentDone = true"

          @previous-tab="switchTab(tabs[activeTabIndex -1].component)"
          @next-tab="switchTab($event || tabs[activeTabIndex +1].component)"
          @selected-offer="getSelectedOffer($event)"
          @setup-intent="getSetupIntent($event)"
        />
      </keep-alive>
    </b-card>

  </div>
</template>

<script>
import { findIndex } from 'lodash'

import AppDisconnectedHeader from '../../layouts/components/AppDisconnectedHeader.vue'

import Thanks from './tab/RegistrationThanks.vue'
import Payment from './tab/RegistrationPayment.vue'
import Cgv from './tab/RegistrationCgv.vue'
import Offers from './tab/RegistrationOffers.vue'

export default {
  name: 'RegistrationWizard',
  components: {
    Offers,
    Cgv,
    Payment,
    Thanks,
    AppDisconnectedHeader,
  },
  data() {
    return {
      saveLoader: false,
      currentTab: null,
      stepIndex: 0,
      selectedOffer: {
        id: null,
      },
      setupIntent: {
        id: null,
      },
      paymentDone: false,
      activeTabIndex: 0,
      tabs: [
        {
          title: this.$t('registration.offers.title'),
          component: 'offers',
        },
        {
          title: this.$t('registration.cgv.title'),
          component: 'cgv',
        },
        {
          title: this.$t('registration.payment.title'),
          component: 'payment',
        },
        {
          title: this.$t('registration.thanks'),
          component: 'thanks',
        },
      ],
    }
  },
  watch: {
    '$route.params.tab': {
      handler(tabName) {
        this.activeTab = tabName || 'offers'
        const activeTabIndex = findIndex(this.tabs, tab => tab.component === this.activeTab)

        if (activeTabIndex !== -1) this.activeTabIndex = activeTabIndex
      },
      immediate: true,
    },
  },
  methods: {
    switchTab(componentName) {
      this.$router.push({
        ...this.$route,
        params: {
          tab: componentName,
        },
      })
    },
    formSubmitted() {
      this.formSuccess = false
    },
    getSelectedOffer(selectedOffer) {
      this.selectedOffer = selectedOffer
    },
    getSetupIntent(setupIntent) {
      this.setupIntent = setupIntent
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-wizard.scss';

.tabs_service-add .nav-tabs {
  align-items: flex-end;
}

#navbarTabRegistration {
  display: flex;
  justify-content: space-around;
  margin-bottom: 10px !important;
}

#navbarTabRegistration {
  .nav-item {
    padding: 8px;

    .nav-link {
      padding-top: 0;
      padding-bottom: 0;
      padding-left: 0;
      margin-right: 0;
      flex-wrap: nowrap;
      pointer-events: none !important;
      cursor: none;

      .nav-btn {
        border: 1px solid black !important;
        margin-right: 1rem;
        border-radius: 100%;
        width: 2.71rem;
        height: 2.71rem;
        color: white !important;
        background-color: #43A047 !important;
      }
    }
    .nav-link {
      .btn-secondary {
        border: none;
        color: black !important;
        background-color: white !important;
      }
    }

  }
}

.line {
  width: 72%;
  height: 1px;
  background-color: black;
  position: relative;
  bottom: 38px;
  left: 13%;
  z-index: -1;
}

@include media-breakpoint-down(md) {
  .line {
    width: 70%;
  }
}

@include media-breakpoint-down(sm) {
  .line {
    width: 67%;
  }
}

[dir] body.dark-layout #navbarTabRegistration {
  .nav-item {
    border-color: black !important;
    .nav-link {
      .nav-btn {
          color: white !important;
      }
    }

    .nav-link {
      .btn-secondary {
        background-color: black !important;
      }
    }
  }
}

</style>
