<template>
  <b-nav-item-dropdown id="dropdown-grouped" class="dropdown-language" right variant="link">
    <template #button-content>
      <b-img :alt="currentLocale.locale" :src="currentLocale.img" height="18px" width="22px" />
      <span v-if="$store.getters['app/mdAndUp']" class="ml-50 text-body">{{ $t(`lang.${currentLocale.name}.title`) }}</span>
    </template>
    <b-dropdown-item v-for="localeObj in LANGUAGES" :key="localeObj.locale" @click="setLanguage(localeObj.locale)">
      <b-img :alt="localeObj.locale" :src="localeObj.img" height="14px" width="22px" />
      <span class="ml-50">{{ $t(`lang.${localeObj.name}.title`) }}</span>
    </b-dropdown-item>
  </b-nav-item-dropdown>
</template>

<script>
import useAppConfig from '@core/app-config/useAppConfig'

import { loadValidationLocale } from '@/libs/vee-validate'
import LANGUAGES from '@/constants/language'

export default {
  name: 'Locale',

  computed: {
    currentLocale() {
      return LANGUAGES.find(l => l.locale === this.$i18n.locale)
    },
  },
  mounted() {
    if (this.language) {
      this.$i18n.locale = this.language
      // this.$moment.defineLocale(this.language, {
      //   parentLocale: 'en',
      // })
    }
  },
  methods: {
    setLanguage(languageSelected) {
      this.language = languageSelected
      this.$i18n.locale = languageSelected
      this.$moment.locale(languageSelected)
      loadValidationLocale(languageSelected)
    },
  },
  setup() {
    const { language } = useAppConfig()

    return {
      LANGUAGES,
      language,
    }
  },
}
</script>
