<template>
  <section>
    <!--HEADER-->
    <div v-for="offer in offers" :key="offer.id" ref="validatePayment" class="d-flex justify-content-center">
      <b-card v-if="offer.id === selectedOfferId">
        <!-- Prices -->
        <div class="price-details mx-1">
          <h6 class="price-title text-center mb-1">
            {{ $t('registration.payment.price_details') }}
          </h6>
          <ul class="list-unstyled">
            <li class="d-flex justify-content-between">
              <div class="mr-3">{{ $t('registration.payment.total_ht') }} :</div>
              <div>
                {{ offer.subscription.totalDiscountedPrice | priceFormat }}
              </div>
            </li>
            <li class="d-flex justify-content-between">
              <div class="mr-3">{{ $t('registration.payment.vat') }} :</div>
              <div>{{ offer.subscription.vatRate }} %</div>
            </li>
            <li class="d-flex justify-content-between">
              <div class="mr-3">
                {{ $t('registration.payment.total_ttc') }}
                {{ $t(`registration.offers.${offer.subscription.paymentFrequency}`) }} :
              </div>
              <div>
                {{ offer.subscription.totalDisplayPrice | priceFormat }}
              </div>
            </li>
          </ul>
        </div>
      </b-card>
    </div>

    <!--ACTION FOOTER-->
    <div class="d-flex justify-content-center">
      <b-button class="mr-0" @click="isAddNewPaymentMethodSidebarActive = true"> {{
        $t('registration.payment.validate')
      }}
      </b-button>
    </div>

    <app-form-wizard-footer
      class="mt-2"
      previous
      :disable-previous="paymentDone"
      @click:previous="$emit('previous-tab')"
    />

    <sidebar-add-payment-method
      :token="this.$route.query.token"
      :is-add-new-payment-method-sidebar-active.sync="isAddNewPaymentMethodSidebarActive"
      @payment-done="validationForm"
      @setup-intent="getSetupIntent($event)"
    />
  </section>
</template>
<script>
import SidebarAddPaymentMethod from '@/views/registration/sidebar/SidebarAddPaymentMethod.vue'
import number from '@/mixins/number'

import { fetchRegisterSelectOfferRequest } from '@/request/globalApi/requests/registerRequests'
import AppFormWizardFooter from '@/components/AppFormWizardFooter.vue'

export default {
  name: 'RegistrationPayment',
  components: {
    SidebarAddPaymentMethod,
    AppFormWizardFooter,
  },
  mixins: [number],
  props: {
    selectedOfferId: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      offers: {},
      paymentMethods: [],
      isAddNewPaymentMethodSidebarActive: false,
      paymentDone: false,
      setupIntent: null,
    }
  },
  mounted() {
    this.APIFetchOffers()
  },
  methods: {
    async APIFetchOffers() {
      fetchRegisterSelectOfferRequest(this.$route.query.token)
        .then(response => {
          const { offers } = response.data
          this.offers = offers
        })
        .catch(() => false)
    },
    validationForm() {
      this.paymentDone = true
      this.$emit('next-tab')
      return true
    },
    getSetupIntent(setupIntent) {
      this.setupIntent = setupIntent
      this.$emit('setup-intent', setupIntent)
    },
  },
}
</script>
<style scoped></style>
