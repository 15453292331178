<template>
  <div class="container">
    <b-overlay :show="isLoading">
      <vue-pdf-embed
        :source="loadPdf()"
        @rendered="isLoading = false"
      />
    </b-overlay>
  </div>
</template>

<script>
import { defineComponent, ref } from '@vue/composition-api'
import VuePdfEmbed from 'vue-pdf-embed/dist/vue2-pdf-embed'

import cgsEn from '@/assets/pdf/DIVEMA-CGS-annexes_en.pdf'
import cgsFr from '@/assets/pdf/DIVEMA-CGS-annexes_fr.pdf'

export default defineComponent({
  name: 'TermsOfServices',

  components: {
    VuePdfEmbed,
  },

  setup(_props, ctx) {
    const { $store } = ctx.root

    const cgs = {
      en: cgsEn,
      fr: cgsFr,
    }

    const isLoading = ref(true)

    const loadPdf = () => cgs[$store.state.appConfig.lang] || cgs.fr

    return {
      loadPdf,
      isLoading,
    }
  },
})
</script>
