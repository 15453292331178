<template>
  <div class="d-flex">
    <!-- PREVIOUS -->
    <b-button v-if="previous" variant="flat-primary" :disabled="disablePrevious" @click="$emit('click:previous')">
      <font-awesome-icon class="mr-1" icon="arrow-left" />
      <span v-if="currentBreakPoint !=='xs'">{{ $t('common.previous') }}</span>
    </b-button>

    <div class="ml-auto">
      <b-button
        v-if="customBtn"
        type="submit"
        variant="flat-primary"
        class="p-1"
        :disabled="disabled"
        @click="$emit('click:custom')"
      >
        <slot name="custom-btn" />
      </b-button>

      <!-- SAVE -->
      <b-button
        v-if="save"
        type="submit"
        variant="flat-primary"
        class="p-1"
        :disabled="disabled"
        @click="$emit('click:save')"
      >
        <b-spinner v-if="saveLoader" small />
        <slot name="save-btn">
          <font-awesome-icon icon="save" />
          {{ $t('action.save') }}
        </slot>
      </b-button>

      <!-- SAVE & NEXT -->
      <b-button
        v-if="saveNext"
        type="submit"
        variant="flat-primary"
        class="p-1"
        :disabled="disabled"
        @click="$emit('click:save-next')"
      >
        <span v-if="currentBreakPoint !=='xs'">{{ $t('action.save_and_next') }}</span>
        <b-spinner v-if="saveLoader" small />
        <font-awesome-icon v-if="currentBreakPoint ==='xs'" icon="save" />
        <font-awesome-icon class="ml-1" icon="arrow-right" />
      </b-button>

      <!-- NEXT -->
      <b-button v-if="next" variant="flat-primary" class="p-1" @click="$emit('click:next')">
        <span v-if="currentBreakPoint !=='xs'">{{ $t('common.next') }}</span>
        <font-awesome-icon icon="arrow-right" />
      </b-button>
    </div>
  </div>
</template>

<script>
import { defineComponent, computed } from '@vue/composition-api'

export default defineComponent({
  name: 'AppFormWizardFooter',
  props: {
    saveLoader: {
      type: Boolean,
      default: false,
    },
    previous: {
      type: Boolean,
      default: false,
    },
    customBtn: {
      type: Boolean,
      default: false,
    },
    save: {
      type: Boolean,
      default: false,
    },
    next: {
      type: Boolean,
      default: false,
    },
    saveNext: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    disablePrevious: {
      type: Boolean,
      default: false,
    },
  },

  setup(_props, ctx) {
    const { $store } = ctx.root
    const currentBreakPoint = computed(() => $store.getters['app/currentBreakPoint'])

    return {
      currentBreakPoint,
    }
  },
})
</script>
