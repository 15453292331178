import { render, staticRenderFns } from "./RegistrationOffers.vue?vue&type=template&id=345afca6&scoped=true&"
import script from "./RegistrationOffers.vue?vue&type=script&lang=js&"
export * from "./RegistrationOffers.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "345afca6",
  null
  
)

export default component.exports