<template>
  <div class="col-12">
    <div class="card mx-2 mt-1 mb-0 ">
      <div class="card-body d-flex flex-wrap ">
        <div class="col-6 align-items-center justify-content-start">
          <div class="brand-logo">
            <b-img
              style="object-fit: cover;width: 135px;"
              :src="logoFull"
              alt="logo"
            />
          </div>
        </div>

        <div class="col-6 d-flex flex-wrap justify-content-end align-items-center">
          <dark-toggler class="d-block" />
          <locale class="d-block" />
          <b-button
            variant="outline-primary mx-1"
            :to="{name : 'contact'}"
          >
            <span> {{ $t('login.contact_us') }}</span>
          </b-button>
        </div>
      </div>
    </div>

  </div>
</template>

<script>
import Locale from './app-navbar/components/Locale.vue'
import DarkToggler from './app-navbar/components/DarkToggler.vue'

export default {
  name: 'AppDisconnectedHeader',
  components: {
    Locale,
    DarkToggler,
  },
  data() {
    return {
      logoFull: require('@/assets/images/logo/logo.svg'),
    }
  },
}
</script>
