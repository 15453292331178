<template>
  <section>
    <b-row v-if="selectedOffer.seller">
      <b-col class="container cgv-overflow" cols="6">
        <terms-of-use />
      </b-col>

      <b-col class="container cgv-overflow" cols="6">
        <terms-of-services />
      </b-col>
    </b-row>
    <b-row v-else>
      <b-col class="container cgv-overflow" cols="12">
        <terms-of-use />
      </b-col>
    </b-row>
    <validation-observer
      ref="checkCgv"
    >
      <div class="col-12 mt-4 d-flex align-items-center justify-content-center">

        <validation-provider
          #default="{ errors }"
          :rules="{ required: { allowFalse: false } }"
          name="cgvCheck"
        >
          <b-form-checkbox
            v-model="cgvCheck"
            :state="errors.length > 0 ? false:null"
            value="cgvCheck"
          >
            <span v-if="selectedOffer.seller" class="h4 mb-0 col-12"> {{ $t('trip.shop.checkout.accept_terms_of_service_and_commercial_terms') }} </span>
            <span v-else class="h4 mb-0 col-12"> {{ $t('trip.shop.checkout.accept_commercial_terms') }} </span>
          </b-form-checkbox>
        </validation-provider>
      </div>
      <app-form-wizard-footer class="mt-2" previous save-next @click:previous="$emit('previous-tab')" @click:save-next="validationForm()" />

    </validation-observer>
  </section>
</template>

<script>
import TermsOfServices from '@/views/policy/TermsOfServices.vue'
import TermsOfUse from '@/views/policy/TermsOfUse.vue'
import AppFormWizardFooter from '@/components/AppFormWizardFooter.vue'

export default {
  name: 'RegistrationCgv',
  components: {
    TermsOfServices,
    TermsOfUse,
    AppFormWizardFooter,
  },
  props: {
    selectedOfferId: {
      type: Number,
      required: true,
    },
    selectedOffer: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      cgvCheck: '',
      nextRedirection: false,
    }
  },
  methods: {
    validationForm() {
      if (this.cgvCheck) {
        this.$emit('next-tab')
        return true
      }
      this.toaster(this.$t('alert.fill_required_fields'), 'danger', 'AlertTriangleIcon')
      return false
    },
  },
}
</script>

<style scoped>
.cgv-overflow {
  max-height: 55vh;
  overflow: auto;

  width: 100%;
}
</style>
